import * as React from "react";
import { graphql } from "gatsby";
import Home from "../modules/Home";
import { useTranslation } from "react-i18next";

const IndexPage = ({ location }) => {
  const { t } = useTranslation();
  return <Home t={t} />;
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      nodes {
        data
        language
      }
    }
  }
`;
